import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { DanhMucTaiLieu } from 'src/app/shared/DanhMucTaiLieu.model';
import { DanhMucTaiLieuService } from 'src/app/shared/DanhMucTaiLieu.service';

@Component({
  selector: 'app-danh-muc-tai-lieu',
  templateUrl: './danh-muc-tai-lieu.component.html',
  styleUrls: ['./danh-muc-tai-lieu.component.css']
})
export class DanhMucTaiLieuComponent implements OnInit {

  @ViewChild('DanhMucTaiLieuSort') DanhMucTaiLieuSort: MatSort;
  @ViewChild('DanhMucTaiLieuPaginator') DanhMucTaiLieuPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,

    public DanhMucTaiLieuService: DanhMucTaiLieuService,
  ) { }

  ngOnInit(): void {
    this.DanhMucTaiLieuSearch();
  }
  DanhMucTaiLieuSearch() {
    if (this.DanhMucTaiLieuService.BaseParameter.SearchString.length > 0) {
      this.DanhMucTaiLieuService.DataSource.filter = this.DanhMucTaiLieuService.BaseParameter.SearchString.toLowerCase();
    }
    else {
      this.DanhMucTaiLieuService.IsShowLoading = true;
      this.DanhMucTaiLieuService.GetAllAndEmptyToListAsync().subscribe(
        res => {
          this.DanhMucTaiLieuService.List = (res as DanhMucTaiLieu[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.DanhMucTaiLieuService.ListFilter = this.DanhMucTaiLieuService.List.filter(item => item.ParentID == 0);
          this.DanhMucTaiLieuService.DataSource = new MatTableDataSource(this.DanhMucTaiLieuService.List);
          this.DanhMucTaiLieuService.DataSource.sort = this.DanhMucTaiLieuSort;
          this.DanhMucTaiLieuService.DataSource.paginator = this.DanhMucTaiLieuPaginator;
          this.DanhMucTaiLieuService.IsShowLoading = false;
        },
        err => {
          this.DanhMucTaiLieuService.IsShowLoading = false;
        }
      );
    }
  }

  DanhMucTaiLieuSave(element: DanhMucTaiLieu) {
    this.DanhMucTaiLieuService.FormData = element;
    this.NotificationService.warn(this.DanhMucTaiLieuService.ComponentSaveAll(this.DanhMucTaiLieuSort, this.DanhMucTaiLieuPaginator));
  }
  DanhMucTaiLieuDelete(element: DanhMucTaiLieu) {
    this.DanhMucTaiLieuService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucTaiLieuService.ComponentDeleteAll(this.DanhMucTaiLieuSort, this.DanhMucTaiLieuPaginator));
  }
}

