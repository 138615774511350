import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DanhMucThanhVien } from 'src/app/shared/DanhMucThanhVien.model';
import { DanhMucThanhVienService } from 'src/app/shared/DanhMucThanhVien.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

@Component({
  selector: 'app-thanh-vien-dang-ky',
  templateUrl: './thanh-vien-dang-ky.component.html',
  styleUrls: ['./thanh-vien-dang-ky.component.css']
})
export class ThanhVienDangKyComponent implements OnInit {

  constructor(
    public NotificationService: NotificationService,

    public ThanhVienService: ThanhVienService,
  ) { }

  ngOnInit(): void {
    document.getElementById("sidebar").style.display = "none";
    document.getElementById("main-container").style.padding = "0";
  }
  
  ngOnDestroy(): void {
    document.getElementById("sidebar").style.display = "block";
    document.getElementById("main-container").style.padding = "0 0 0 300px";
  }

  ThanhVienSave() {
    this.ThanhVienService.IsShowLoading = true;
    this.ThanhVienService.FormDataLogin.ID = 0;
    this.ThanhVienService.FormDataLogin.Active = false;
    this.ThanhVienService.FormData = this.ThanhVienService.FormDataLogin;
    this.ThanhVienService.SaveAsync().subscribe(
      res => {
        this.ThanhVienService.FormDataLogin = res as ThanhVien;
        this.NotificationService.warn(environment.SaveSuccess);
        alert("Đăng ký tạo tài khoản thành công");
        this.ThanhVienService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.ThanhVienService.IsShowLoading = false;
      }
    );
  }
}
