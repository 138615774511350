<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-TaiLieu" data-bs-toggle="tab"
                                    href="#TaiLieu" role="tab" aria-controls="TaiLieu"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Tài liệu</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="TaiLieu" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <select class="form-select"
                                            [(ngModel)]="TaiLieuService.FormData.DanhMucTaiLieuParentID"
                                            
                                            (change)="DanhMucTaiLieuChange()"
                                            >
                                            <!--
                                            <option [value]="0">
                                                Tất cả</option>-->
                                            <option *ngFor="let item of DanhMucTaiLieuService.ListParent;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <select class="form-select"
                                            [(ngModel)]="TaiLieuService.FormData.DanhMucTaiLieuID">
                                            <option *ngFor="let item of DanhMucTaiLieuService.ListChildren;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <input [(ngModel)]="TaiLieuService.BaseParameter.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="TaiLieuSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div class="row gx-4">
                                            <div class="col-lg-6 col-sm-12 col-12">
                                                <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                                (click)="TaiLieuSearch()"><i
                                                    class="bi bi-search"></i></a>
                                            </div>
                                            <div class="col-lg-6 col-sm-12 col-12">
                                                <a style="width: 100%;" class="btn btn-success" title="Thêm mới"
                                                (click)="TaiLieuAdd(0)"><i class="bi bi-plus"></i></a>
                                            </div>
                                        </div>

                                        
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span
                                                *ngIf="TaiLieuService.List">({{TaiLieuService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #TaiLieuSort="matSort"
                                                    [dataSource]="TaiLieuService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="TaiLieuPaginator">
                                                                {{TaiLieuPaginator.pageSize *
                                                                TaiLieuPaginator.pageIndex + i + 1}}
                                                                [{{element.ID}}]
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="link-primary" (click)="TaiLieuAdd(element.ID)"><b><span>{{element.Name}}</span></b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucTaiLieuParentName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Danh mục cha
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="link-primary" (click)="TaiLieuAdd(element.ID)"><b><span>{{element.DanhMucTaiLieuParentName}}</span></b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucTaiLieuName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Danh mục con
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="link-primary" (click)="TaiLieuAdd(element.ID)"><b><span>{{element.DanhMucTaiLieuName}}</span></b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayTao">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày tạo
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <span>{{element.NgayTao  | date:'dd/MM/yyyy' }}</span>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TacGia">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tác giả
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <span>{{element.TacGia}}</span>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="TaiLieuSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="TaiLieuDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a><!-- &nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-success"
                                                                (click)="TaiLieuAdd(element.ID)"
                                                                *ngIf="element.ID>0"><i class="bi bi-shield-check"></i>
                                                            </a> -->
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="TaiLieuService.DisplayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: TaiLieuService.DisplayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #TaiLieuPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #TaiLieuSort="matSort"
                                                    [dataSource]="TaiLieuService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Code</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Code" name="Code{{element.ID}}"
                                                                        [(ngModel)]="element.Code">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tiêu đề</label>
                                                                    <span>{{element.Code}}</span>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Ghi chú</label>
                                                                    <span>{{element.Note}}</span>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Parent</label>
                                                                    <input class="form-control" type="number"
                                                                        placeholder="0" name="ParentID{{element.ID}}"
                                                                        [(ngModel)]="element.ParentID"
                                                                        style="text-align: right;">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Sắp xếp</label>
                                                                    <input class="form-control" type="number"
                                                                        placeholder="0" name="SortOrder{{element.ID}}"
                                                                        [(ngModel)]="element.SortOrder"
                                                                        style="text-align: right;">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="Active{{element.ID}}"
                                                                            [(ngModel)]="element.Active">
                                                                        <label class="form-label">Kích hoạt</label>
                                                                    </div>
                                                                </div>
                                                                <div class="row gx-4">
                                                                    <div class="col-lg-4 col-sm-4 col-4">
                                                                        <a class="btn btn-info" style="width: 100%;"
                                                                            (click)="TaiLieuSave(element)"><i
                                                                                class="bi bi-sd-card"></i>
                                                                        </a>
                                                                    </div>
                                                                    <div class="col-lg-4 col-sm-4 col-4">
                                                                        <a class="btn btn-danger" style="width: 100%;"
                                                                            (click)="TaiLieuDelete(element)"
                                                                            *ngIf="element.ID>0"><i
                                                                                class="bi bi-trash"></i>
                                                                        </a>
                                                                    </div>
                                                                    <div class="col-lg-4 col-sm-4 col-4">
                                                                        <a class="btn btn-danger" style="width: 100%;"
                                                                            (click)="TaiLieuAdd(element)"
                                                                            *ngIf="element.ID>0"><i
                                                                                class="bi bi-trash"></i>
                                                                        </a>
                                                                    </div>
                                                                    <br />
                                                                    <br />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="TaiLieuService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: TaiLieuService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #TaiLieuPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="TaiLieuService.IsShowLoading"></app-loading>