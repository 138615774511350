export const environment = {
  production: false,
  DomainURL: "",
  DomainDestination: "",  
  APIURL: "https://apiacc.vnbook.vn/api/v1/",
  APIRootURL: "https://apiacc.vnbook.vn/",
  APIUploadURL: "/api/v1/",
  APIUploadRootURL: "/",
  LoginURL: "/",
  Website: "/",
  Image: "Image",
  Company: "Company",
  Membership: "Membership",
  Barcode: "Barcode",
  QRcode: "QRcode",
  OrderDelivery: "OrderDelivery",
  MapIcon: "https://bando.vungtrong.com/Image/HTX4_30.png",
  Download: "Download",
  IPRegistry: "https://ipv4.myexternalip.com/json",
  LoadingFile: "loading.gif",
  DialogConfigWidth: "80%",
  DialogConfigWidth60: "60%",
  InitializationString: "",
  InitializationNumber: 0,
  DocumentTemplateIDATTPBienBanThamDinh: 37,
  BienBanATTPParentID: 1,
  ProvinceDataIDBenTre: 53,
  CoQuanQuanLyID: 1,
  CompanyInfoID: 33,
  DanhMucATTPTinhTrangID: 1,
  ThanhVienIDNumber: 80,
  PlanTypeIDCoSoNuoi: 11,
  PlanTypeIDDangKyATTP: 9,
  PlanTypeIDThamDinhATTP: 3,
  PlanTypeIDChuoiCungUng: 7,
  PlanTypeIDGiamSatDuLuong: 1,
  PlanTypeIDAnToanThucPhamSauThuHoach: 4,
  PlanTypeIDCamKet17: 12,
  PlanTypeIDNhuyenTheHaiManhVo: 2,
  PlanTypeIDTuCongBoSanPham: 13,
  PlanTypeIDThanhTraChuyenNganh: 8,
  PlanTypeIDKiemTraTapChat: 5,
  PlanTypeIDDangKyMaDongGoi: 10,
  PlanTypeIDDangKyMaVungTrong: 6,
  DanhMucSanPhamID: 890,
  DanhMucDuAnID: 1,
  ThanhVienQuanTriID: 3,
  ThanhVienCoQuanQuanLyID: 2,
  ThanhVienChuyenGiaID: 4,
  ThanhVienNongDanID: 1,
  DanhMucThanhVienIDKhachMoi: 6,
  DanhMucThanhVienIDNhanVien: 5,
  DanhMucTinhThanhID: 58,
  MapZoom: 8,
  Latitude: 10.235012,
  Longitude: 106.3797368,
  StateAgencyIDChiCucQLCLNongLamThuySan: 1,
  StateAgencyIDChiCucBaoVeThucVat: 2,
  StateAgencyIDChiCucThuySan: 3,
  ProductUnitIDTan: 8,
  DanhMucQuocGiaIDVietNam: 1,
  PlanThamDinh: "PlanThamDinh",
  MaptilerAPIKey: "6iFTqps4QVACLERa26MA",
  TokenDefault: "d85df3cb-5688-4b1d-b120-50479b23d2a0",
  Token: "Token",
  TokenFCM: "TokenFCM",
  ThanhVienCompanyInfoID: "ThanhVienCompanyInfoID",
  ThanhVienParentID: "ThanhVienParentID",
  ThanhVienID: "ThanhVienID",
  ThanhVienHoTen: "ThanhVienHoTen",
  ThanhVienTaiKhoan: "ThanhVienTaiKhoan",
  ThanhVienFileName: "ThanhVienFileName",
  ZTong: "Z.Tổng",
  HTMLExtension: ".html",
  Homepage: "Homepage",
  Login: "Login",
  UploadSuccess: "Upload thành công.",
  UploadNotSuccess: "Upload không thành công.",
  SaveSuccess: "Lưu thành công.",
  SaveNotSuccess: "Lưu không thành công.",
  DeleteConfirm: "Bạn muốn xóa dữ liệu này?",
  DeleteSuccess: "Xóa thành công.",
  DeleteNotSuccess: "Xóa không thành công.",
  LoginNotSuccess: "Đăng nhập không thành công.",
  UserNameExists: "Tài khoản đã tồn tại.",
  UserNameRequired: "UserName là bắt buộc.",
  HTX0001: "Hợp tác xã chưa có dữ liệu Năng lực sản xuất.",
};
