import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DanhMucTaiLieu } from 'src/app/shared/DanhMucTaiLieu.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class DanhMucTaiLieuService extends BaseService{

    List: DanhMucTaiLieu[] | undefined;
    ListParent: DanhMucTaiLieu[] | undefined;
    ListChildren: DanhMucTaiLieu[] | undefined;
    ListFilter: DanhMucTaiLieu[] | undefined;
    FormData!: DanhMucTaiLieu;

    DisplayColumns001: string[] = ['STT', 'ParentID','Name', 'SortOrder', 'Active','Save'];

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "DanhMucTaiLieu";
    }
    
}

