import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';

import { DanhMucTaiLieu } from 'src/app/shared/DanhMucTaiLieu.model';
import { DanhMucTaiLieuService } from 'src/app/shared/DanhMucTaiLieu.service';



import { TaiLieu } from 'src/app/shared/TaiLieu.model';
import { TaiLieuService } from 'src/app/shared/TaiLieu.service';

//import { TaiLieuFileDinhKem } from 'src/app/shared/TaiLieuFileDinhKem.model';
//import { TaiLieuFileDinhKemService } from 'src/app/shared/TaiLieuFileDinhKem.service';

@Component({
  selector: 'app-tai-lieu-detail',
  templateUrl: './tai-lieu-detail.component.html',
  styleUrls: ['./tai-lieu-detail.component.css']
})
export class TaiLieuDetailComponent implements OnInit {

  checkSave?: boolean;

  @ViewChild('TaiLieuFileDinhKemSort') TaiLieuFileDinhKemSort: MatSort;
  @ViewChild('TaiLieuFileDinhKemPaginator') TaiLieuFileDinhKemPaginator: MatPaginator;

  constructor(
    public dialogRef: MatDialogRef<TaiLieuDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,

    public DanhMucTaiLieuService: DanhMucTaiLieuService,

    public TaiLieuService: TaiLieuService,
    //public TaiLieuFileDinhKemService: TaiLieuFileDinhKemService,
  ) { }

  ngOnInit(): void {
    this.DanhMucTaiLieuSearch();
    this.checkSave = false;
    //this.TaiLieuFileDinhKemSearch();

    if(this.TaiLieuService.FormData.DanhMucTaiLieuID){
      this.DanhMucTaiLieuChildrenSearch();
    }
    else{
      this.DanhMucTaiLieuService.ListChildren =[];
    }
  }

  NgayTao_date(value) {
    this.TaiLieuService.FormData.NgayTao = new Date(value);
  }

  DanhMucTaiLieuChange(){
    this.TaiLieuService.FormData.DanhMucTaiLieuID =null;
    this.DanhMucTaiLieuChildrenSearch();
  }
  
  DanhMucTaiLieuSearch() {
    if (this.DanhMucTaiLieuService.ListParent ==null || this.DanhMucTaiLieuService.ListParent.length == 0) 
    {
      this.TaiLieuService.IsShowLoading = true;
      this.DanhMucTaiLieuService.BaseParameter.ParentID = environment.InitializationNumber;
      this.DanhMucTaiLieuService.GetByParentIDToListAsync().subscribe(
        res => {
          this.DanhMucTaiLieuService.ListParent = (res as TaiLieu[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.TaiLieuService.IsShowLoading = false;
          this.DanhMucTaiLieuChildrenSearch();
        },
        err => {
          this.TaiLieuService.IsShowLoading = false;
        }
      );
    }
  }

  DanhMucTaiLieuChildrenSearch() {
      if( this.TaiLieuService.FormData.DanhMucTaiLieuParentID >0)
      {
      //if (this.DanhMucTaiLieuService.ListChildren.length == 0) 
        {
          this.TaiLieuService.IsShowLoading = true;
          this.DanhMucTaiLieuService.BaseParameter.ParentID = this.TaiLieuService.FormData.DanhMucTaiLieuParentID;
          this.DanhMucTaiLieuService.GetByParentIDToListAsync().subscribe(
            res => {
              this.DanhMucTaiLieuService.ListChildren = (res as TaiLieu[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
              this.TaiLieuService.IsShowLoading = false;
            },
            err => {
              this.TaiLieuService.IsShowLoading = false;
            }
          );
        }
      }
      /*
      else{
        this.DanhMucTaiLieuService.ListChildren =[];
      }*/
  }

  ChangeFileName(files: FileList) {

    if (files) {
      if (files.length > 0) {
        this.TaiLieuService.FileToUpload = files;
        let fileToUpload0: File = null;
        fileToUpload0 = files.item(0);
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.TaiLieuService.FormData.FileName = event.target.result;
        };
        reader.readAsDataURL(fileToUpload0);
      }
    }
  }

  ChangeFileTaiLieuName(files: FileList) {

    if (files) {
      if (files.length > 0) {
        this.TaiLieuService.FileTaiLieuToUpload = files;
        let fileToUpload0: File = null;
        fileToUpload0 = files.item(0);
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.TaiLieuService.FormData.FileTaiLieuName = event.target.result;
        };
        reader.readAsDataURL(fileToUpload0);
      }
    }
  }

  TaiLieuSave() {
    this.TaiLieuService.IsShowLoading = true;
    this.TaiLieuService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.TaiLieuService.FormData = res as TaiLieu;
        //this.NotificationService.success(environment.SaveSuccess);
        this.TaiLieuService.IsShowLoading = false;
        this.TaiLieuSaveFileTaiLieu();
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.TaiLieuService.IsShowLoading = false;
      }
    );
  }

  TaiLieuSaveFileTaiLieu() {
    this.TaiLieuService.IsShowLoading = true;
    this.TaiLieuService.SaveAndUploadFileTaiLieuAsync().subscribe(
      res => {
        this.TaiLieuService.FormData = res as TaiLieu;
        this.NotificationService.success(environment.SaveSuccess);
        this.TaiLieuService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.TaiLieuService.IsShowLoading = false;
      }
    );
  }

  

  Close() {
    this.dialogRef.close();
  }

  /*
  TaiLieuFileDinhKemSearch() {
    this.TaiLieuFileDinhKemService.BaseParameter.ParentID = this.TaiLieuService.FormData.ID;
    this.TaiLieuFileDinhKemService.SearchByParentID(this.TaiLieuFileDinhKemSort, this.TaiLieuFileDinhKemPaginator);
  }
  TaiLieuFileDinhKemSave(element: TaiLieuFileDinhKem) {
    this.TaiLieuService.IsShowLoading = true;
    element.ParentID = this.TaiLieuService.FormData.ID;
    this.TaiLieuFileDinhKemService.FormData = element;
    this.TaiLieuFileDinhKemService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.TaiLieuFileDinhKemSearch();
        this.NotificationService.success(environment.SaveSuccess);
        this.TaiLieuService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.TaiLieuService.IsShowLoading = false;
      }
    );
  }
  TaiLieuFileDinhKemDelete(element: TaiLieuFileDinhKem) {
    this.TaiLieuFileDinhKemService.BaseParameter.ID = element.ID;
    this.TaiLieuFileDinhKemService.RemoveAsync().subscribe(
      res => {
        this.TaiLieuFileDinhKemSearch();
        this.NotificationService.success(environment.DeleteSuccess);
        this.TaiLieuService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.DeleteNotSuccess);
        this.TaiLieuService.IsShowLoading = false;
      }
    );
  }


  TaiLieuFileDinhKemChangeFile(element: TaiLieuFileDinhKem, files: FileList) {
    if (files) {
      if (files.length > 0) {
        let fileToUpload0: File = null;
        this.TaiLieuFileDinhKemService.FileToUpload = files;
        fileToUpload0 = files.item(0);
        var reader = new FileReader();
        reader.onload = (event: any) => {
          element.FileName = event.target.result;
        };
        reader.readAsDataURL(fileToUpload0);
      }
    }
  }
  */
}
