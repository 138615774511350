import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from 'ngx-ckeditor';
import { ChartsModule } from 'ng2-charts';
import { CookieService } from 'ngx-cookie-service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MaterialModule } from './material/material.module';
import { GoogleMapsModule } from '@angular/google-maps';

import { NotificationService } from './shared/Notification.service';


import { AppComponent } from './app.component';
import { LoadingComponent } from './loading/loading.component';
import { HomepageComponent } from './homepage/homepage.component';
import { ThanhVienComponent } from './thanh-vien/thanh-vien.component';
import { ThanhVienThongTinComponent } from './thanh-vien-thong-tin/thanh-vien-thong-tin.component';
import { ThanhVienDetailComponent } from './thanh-vien-detail/thanh-vien-detail.component';
import { LoginComponent } from './login/login.component';

import { Dashboard001Component } from './dashboard001/dashboard001.component';
import { DanhMucThanhVienComponent } from './danh-muc-thanh-vien/danh-muc-thanh-vien.component';
import { DanhMucThanhVienDetailComponent } from './danh-muc-thanh-vien-detail/danh-muc-thanh-vien-detail.component';
import { DanhMucTaiLieuComponent } from './danh-muc-tai-lieu/danh-muc-tai-lieu.component';
import { TaiLieuComponent } from './tai-lieu/tai-lieu.component';
import { TaiLieuDetailComponent } from './tai-lieu-detail/tai-lieu-detail.component';
import { ThanhVienDangKyComponent } from './thanh-vien-dang-ky/thanh-vien-dang-ky.component';



@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    HomepageComponent,
    ThanhVienComponent,
    ThanhVienThongTinComponent,
    ThanhVienDetailComponent,
    LoginComponent,
    Dashboard001Component,    
    DanhMucThanhVienComponent,
    DanhMucThanhVienDetailComponent,
    DanhMucTaiLieuComponent,
    TaiLieuComponent,
    TaiLieuDetailComponent,
    ThanhVienDangKyComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    GoogleMapsModule,
    ChartsModule,
    CKEditorModule,
  ],
  providers: [
    CookieService,
    NotificationService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]   
})
export class AppModule { }
