<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="tab-TaiLieuDetail" data-bs-toggle="tab" href="#TaiLieuDetail" role="tab"
          aria-controls="TaiLieuDetail" aria-selected="true">
          <span class="badge bg-info" style="font-size: 16px">Thông tin</span>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a style="cursor: pointer" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()" role="tab"
          aria-controls="Close" aria-selected="false">
          <span class="badge bg-danger" style="font-size: 16px"><i class="bi bi-x-lg"></i></span>
        </a>
      </li>
    </ul>
    <div class="tab-content" id="customTabContent">
      <div class="tab-pane fade show active" id="TaiLieuDetail" role="tabpanel">
        <div class="row gx-4">
          <div class="col-lg-6 col-sm-12 col-12">
            <div class="row gx-4">
              <div class="col-lg-6 col-sm-12 col-6">
                
              </div>
              <!--
              <div class="col-lg-6 col-sm-12 col-6" style="text-align: center;">
                <a style="width: 100%" title="Lưu thay đổi" (click)="TaiLieuCopy()" class="btn btn-info"><i
                    class="bi bi-sd-card"></i> Copy</a>
              </div>
              -->
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
              <label class="form-label">Danh mục cha</label>
              <select class="form-select" [(ngModel)]="TaiLieuService.FormData.DanhMucTaiLieuParentID"
                (change) = "DanhMucTaiLieuChange()"
              >
                <option *ngFor="let item of DanhMucTaiLieuService.ListParent" [value]="item.ID">
                  {{ item.Name }}
                </option>
              </select>
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
              <label class="form-label">Danh mục con</label>
              <select class="form-select" [(ngModel)]="TaiLieuService.FormData.DanhMucTaiLieuID">
                <option *ngFor="let item of DanhMucTaiLieuService.ListChildren" [value]="item.ID">
                  {{ item.Name }}
                </option>
              </select>
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
              <label class="form-label">Tên tài liệu</label>
              <input name="Name" [(ngModel)]="TaiLieuService.FormData.Name" placeholder="Tên tài liệu" type="text"
                class="form-control" />
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
              <label class="form-label">Mô tả ngắn</label>
              <input name="Description" [(ngModel)]="TaiLieuService.FormData.Description" placeholder="Mô tả ngắn" type="text"
                class="form-control" />
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
              <div class="form-check form-check-inline mb-6">
                <input type="checkbox" class="form-check-input" name="Active"
                  [(ngModel)]="TaiLieuService.FormData.Active" />
                <label class="form-check-label" for="Active">Kích hoạt</label>
              </div>
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
              <label class="form-label">Hình đại diện: </label>
              <br />
              <input type="file" (change)="ChangeFileName($event.target.files)" />
              <br />
              <br />
              <a target="_blank" href="{{TaiLieuService.FormData.FileName}}"><img width="300" height="200"
                  src="{{TaiLieuService.FormData.FileName}}" /></a>
            </div>
            
          </div>
          <div class="col-lg-6 col-sm-12 col-12">
            <div class="col-lg-12 col-sm-12 col-12">
              <label class="form-label">Số lượt xem</label>
              <input name="SoLuotXem" [(ngModel)]="TaiLieuService.FormData.SoLuotXem" placeholder="Số lượt xem" type="number"
                class="form-control" />
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
              <label class="form-label">Số lượt tải</label>
              <input name="SoLuotTai" [(ngModel)]="TaiLieuService.FormData.SoLuotTai" placeholder="Số lượt tải" type="number"
                class="form-control" />
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
              <label class="form-label">Ngày tạo</label>
              <input 
                            [ngModel]="TaiLieuService.FormData.NgayTao | date:'yyyy-MM-dd'"
                            (ngModelChange)="NgayTao_date($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                            type="date" class="form-control" autocomplete="off">
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
              <label class="form-label">Tác giả</label>
              <input name="TacGia" [(ngModel)]="TaiLieuService.FormData.TacGia" placeholder="Tác giả" type="text"
                class="form-control" />
            </div>
            
            <div class="col-lg-12 col-sm-12 col-12">
              <div class="form-check form-check-inline mb-6">
                <input type="checkbox" class="form-check-input" name="IsCongBoMienPhi"
                  [(ngModel)]="TaiLieuService.FormData.IsCongBoMienPhi" />
                <label class="form-check-label" for="IsCongBoMienPhi">Công bố miễn phí</label>
              </div>
            </div>
            <div class="col-lg-12 col-sm-12 col-12" *ngIf="TaiLieuService.FormData.IsCongBoMienPhi!=true">
              <label class="form-label">Giá tiền</label>
              <input name="GiaTien" [(ngModel)]="TaiLieuService.FormData.GiaTien" placeholder="Giá tiền" type="number"
                class="form-control" />
            </div>
            <!--
            <ck-editor [config]="{ height: 900, width: 800 }" name="HTMLContent"
              [(ngModel)]="TaiLieuService.FormData.HTMLContent" skin="moono-lisa" language="en"
              [fullPage]="true"></ck-editor>
            -->
            <div class="col-lg-12 col-sm-12 col-12">
              <label class="form-label">Tài liệu đính kèm: </label>
              <br />
              <input type="file" (change)="ChangeFileTaiLieuName($event.target.files)" />
              <br />
              <br />
              <a class="link-primary" *ngIf="TaiLieuService.FormData.FileTaiLieuName" target="_blank" href="{{TaiLieuService.FormData.FileTaiLieuName}}"><b>Tải tài liệu</b></a>
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
              <div class="form-check form-check-inline mb-6">
                <input type="checkbox" class="form-check-input" name="this.checkSave"
                  [(ngModel)]="this.checkSave" />
                <label class="form-check-label" for="this.checkSave">Tài liệu tự tổng hợp và công bố, người đăng chịu trách nhiệm về bản quyền</label>
              </div>
            </div>
            <div class="col-lg-12 col-sm-12 col-12">
              <a *ngIf="this.checkSave" style="width: 100%" title="Lưu thay đổi" (click)="TaiLieuSave()" class="btn btn-info"><i
                class="bi bi-sd-card"></i> Lưu thay đổi</a>
            </div>
          </div>
        </div>
        <!--
        <div id="TaiLieuFileDinhKem">
          <div class="card mb-4 card-info">
            <div class="card-header">
              <h5 class="card-title text-white">
                Danh sách file đính kèm
                <span *ngIf="TaiLieuFileDinhKemService.List">({{TaiLieuFileDinhKemService.List.length}} items)</span>
              </h5>
            </div>
            <div class="card-body">
              <div class="table-outer">
                <div class="table-responsive">
                  <table *ngIf="!NotificationService.IsMobile" mat-table matSort #TaiLieuFileDinhKemSort="matSort"
                    [dataSource]="TaiLieuFileDinhKemService.DataSource" class="table table-striped m-0">
                    <ng-container matColumnDef="Name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Thông tin
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="row gx-4">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <label class="form-label">Tiêu đề</label>
                            <input class="form-control" type="text" placeholder="Tiêu đề" name="Name{{element.ID}}"
                              [(ngModel)]="element.Name">
                          </div>
                          <div class="col-lg-12 col-sm-12 col-12">
                            <label class="form-label">Phụ đề</label>
                            <input class="form-control" type="text" placeholder="Phụ đề" name="Description{{element.ID}}"
                              [(ngModel)]="element.Description">
                          </div>
                          <div class="col-lg-12 col-sm-12 col-12">
                            <label class="form-label">URL</label>
                            <input class="form-control" type="text" placeholder="URL" name="FileName{{element.ID}}"
                              [(ngModel)]="element.FileName">
                          </div>
                          <div class="col-lg-12 col-sm-12 col-12">
                            <label class="form-label">Sắp xếp</label>
                            <input class="form-control" type="number" placeholder="0" name="SortOrder{{ element.ID }}"
                              [(ngModel)]="element.SortOrder" style="text-align: right" />
                          </div>
  
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="FileName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Upload
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <input type="file" (change)="TaiLieuFileDinhKemChangeFile(element, $event.target.files)">
                        <br />
                        <br />
                        <a style="cursor: pointer;" title="{{element.Name}}" class="link-primary" target="_blank"
                          href="{{element.FileName}}"><img width="300" height="200" src="{{element.FileName}}" /></a>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Save">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                      <td mat-cell *matCellDef="let element">
                        <a class="btn btn-info" (click)="TaiLieuFileDinhKemSave(element)"><i class="bi bi-sd-card"></i>
                        </a>&nbsp;&nbsp;&nbsp;
                        <a class="btn btn-danger" (click)="TaiLieuFileDinhKemDelete(element)" *ngIf="element.ID > 0"><i
                            class="bi bi-trash"></i>
                        </a>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="TaiLieuFileDinhKemService.DisplayColumns001"></tr>
                    <tr mat-row *matRowDef="let row; columns: TaiLieuFileDinhKemService.DisplayColumns001"></tr>
                  </table>
                  <mat-paginator *ngIf="!NotificationService.IsMobile" #TaiLieuFileDinhKemPaginator="matPaginator"
                    [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                </div>
              </div>
            </div>
          </div>
        </div>
        -->
      </div>
    </div>
  </div>
  <app-loading *ngIf="TaiLieuService.IsShowLoading"></app-loading>