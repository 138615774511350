import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { TaiLieu } from 'src/app/shared/TaiLieu.model';
import { TaiLieuService } from 'src/app/shared/TaiLieu.service';
import { TaiLieuDetailComponent } from '../tai-lieu-detail/tai-lieu-detail.component';

import { DanhMucTaiLieu } from 'src/app/shared/DanhMucTaiLieu.model';
import { DanhMucTaiLieuService } from 'src/app/shared/DanhMucTaiLieu.service';

@Component({
  selector: 'app-tai-lieu',
  templateUrl: './tai-lieu.component.html',
  styleUrls: ['./tai-lieu.component.css']
})
export class TaiLieuComponent implements OnInit {

  @ViewChild('TaiLieuSort') TaiLieuSort: MatSort;
  @ViewChild('TaiLieuPaginator') TaiLieuPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,

    public TaiLieuService: TaiLieuService,
    public DanhMucTaiLieuService: DanhMucTaiLieuService,
  ) { }

  ngOnInit(): void {
    this.DanhMucTaiLieuSearch();
    this.TaiLieuSearch();
  }


  DanhMucTaiLieuChange(){
    this.DanhMucTaiLieuChildrenSearch();
  }
  
  DanhMucTaiLieuSearch() {
    if (this.DanhMucTaiLieuService.ListParent ==null || this.DanhMucTaiLieuService.ListParent.length == 0) 
    {
      this.TaiLieuService.IsShowLoading = true;
      this.DanhMucTaiLieuService.BaseParameter.ParentID = environment.InitializationNumber;
      this.DanhMucTaiLieuService.GetByParentIDToListAsync().subscribe(
        res => {
          this.DanhMucTaiLieuService.ListParent = (res as TaiLieu[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.TaiLieuService.IsShowLoading = false;
          this.DanhMucTaiLieuChildrenSearch();
        },
        err => {
          this.TaiLieuService.IsShowLoading = false;
        }
      );
    }
  }

  DanhMucTaiLieuChildrenSearch() {
    this.DanhMucTaiLieuService.ListChildren =[];
    this.TaiLieuService.FormData.DanhMucTaiLieuID= environment.InitializationNumber;

    if( this.TaiLieuService.FormData.DanhMucTaiLieuParentID >0){
    //if (this.DanhMucTaiLieuService.ListChildren.length == 0) 
      {
        this.TaiLieuService.IsShowLoading = true;
        this.DanhMucTaiLieuService.BaseParameter.ParentID = this.TaiLieuService.FormData.DanhMucTaiLieuParentID;
        this.DanhMucTaiLieuService.GetByParentIDToListAsync().subscribe(
          res => {
            this.DanhMucTaiLieuService.ListChildren = (res as TaiLieu[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
            this.TaiLieuService.IsShowLoading = false;
          },
          err => {
            this.TaiLieuService.IsShowLoading = false;
          }
        );
      }
    }
    else{
      this.DanhMucTaiLieuService.ListChildren =[];
      this.TaiLieuService.FormData.DanhMucTaiLieuID= environment.InitializationNumber;
    }

    
  }

  TaiLieuSearch() {
    this.TaiLieuService.IsShowLoading = true;
    this.TaiLieuService.BaseParameter.DanhMucTaiLieuParentID = this.TaiLieuService.FormData.DanhMucTaiLieuParentID;
    this.TaiLieuService.BaseParameter.DanhMucTaiLieuID = this.TaiLieuService.FormData.DanhMucTaiLieuID;
    this.TaiLieuService.GetByDanhMucTaiLieuParentIDIDAndDanhMucTaiLieuIDAndSearchStringToList().subscribe(
      res => {
        this.TaiLieuService.List = (res as TaiLieu[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.TaiLieuService.DataSource = new MatTableDataSource(this.TaiLieuService.List);
        this.TaiLieuService.DataSource.sort = this.TaiLieuSort;
        this.TaiLieuService.DataSource.paginator = this.TaiLieuPaginator;
        this.TaiLieuService.IsShowLoading = false;
      },
      err => {
        this.TaiLieuService.IsShowLoading = false;
      }
    );
  }
  TaiLieuSave(element: TaiLieu) {
    this.TaiLieuService.FormData = element;
    this.NotificationService.warn(this.TaiLieuService.ComponentSaveAll(this.TaiLieuSort, this.TaiLieuPaginator));
  }
  TaiLieuDelete(element: TaiLieu) {
    this.TaiLieuService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.TaiLieuService.ComponentDeleteAll(this.TaiLieuSort, this.TaiLieuPaginator));
  }

  TaiLieuAdd(ID: number) {
    this.TaiLieuService.BaseParameter.ID = ID;
    this.TaiLieuService.GetByIDAsync().subscribe(
      res => {
        this.TaiLieuService.FormData = res as TaiLieu;
        if(this.TaiLieuService.FormData.ID==0){
          this.TaiLieuService.FormData.DanhMucTaiLieuParentID =null;
          this.TaiLieuService.FormData.DanhMucTaiLieuID =null;
        }

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(TaiLieuDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {       
             this.TaiLieuSearch();
        });
      },
      err => {
      }
    );
  }
}

