import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { ThanhVienComponent } from './thanh-vien/thanh-vien.component';
import { LoginComponent } from './login/login.component';

import { Dashboard001Component } from './dashboard001/dashboard001.component';
import { ThanhVienThongTinComponent } from './thanh-vien-thong-tin/thanh-vien-thong-tin.component';
import { DanhMucThanhVienComponent } from './danh-muc-thanh-vien/danh-muc-thanh-vien.component';
import { DanhMucTaiLieuComponent } from './danh-muc-tai-lieu/danh-muc-tai-lieu.component';
import { TaiLieuComponent } from './tai-lieu/tai-lieu.component';
import { ThanhVienDangKyComponent } from './thanh-vien-dang-ky/thanh-vien-dang-ky.component';



const routes: Routes = [
  { path: '', redirectTo: '/Login', pathMatch: 'full' },
  {
    path: 'Homepage', component: ThanhVienThongTinComponent,
  },
 
  {
    path: 'ThanhVien', component: ThanhVienComponent,
  },
  {
    path: 'ThanhVienThongTin', component: ThanhVienThongTinComponent,
  },
  {
    path: 'ThanhVienDangKy', component: ThanhVienDangKyComponent,
  },
  {
    path: 'Login', component: LoginComponent,
  },
  {
    path: 'Dashboard001', component: Dashboard001Component,
  },
  {
    path: 'DanhMucThanhVien', component: DanhMucThanhVienComponent,
  },
  {
    path: 'DanhMucTaiLieu', component: DanhMucTaiLieuComponent,
  },
  {
    path: 'TaiLieu', component: TaiLieuComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }









































































