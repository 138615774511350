import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TaiLieu } from 'src/app/shared/TaiLieu.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class TaiLieuService extends BaseService{

    List: TaiLieu[] | undefined;
    ListFilter: TaiLieu[] | undefined;
    FormData!: TaiLieu;

    FileTaiLieuToUpload: FileList

    DisplayColumns001: string[] = ['STT','Name','DanhMucTaiLieuParentName','DanhMucTaiLieuName','NgayTao','TacGia' ,'Active','Save'];

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "TaiLieu";
    }

    SaveAndUploadFileTaiLieuAsync() {
        var lastUpdatedMembershipID = localStorage.getItem(environment.ThanhVienID);
        if (lastUpdatedMembershipID) {
            this.FormData.LastUpdatedMembershipID = Number(lastUpdatedMembershipID);
        }
        let url = this.APIURL + this.Controller + '/SaveAndUploadFileTaiLieuAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.FormData));
        if (this.FileTaiLieuToUpload) {
            if (this.FileTaiLieuToUpload.length > 0) {
                for (var i = 0; i < this.FileTaiLieuToUpload.length; i++) {
                    formUpload.append('file[]', this.FileTaiLieuToUpload[i]);
                }
            }
        }
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }


    GetByDanhMucTaiLieuParentIDIDAndDanhMucTaiLieuIDAndSearchStringToList() {        
        let url = this.APIURL + this.Controller + '/GetByDanhMucTaiLieuParentIDIDAndDanhMucTaiLieuIDAndSearchStringToList';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

